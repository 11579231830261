import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import EnterScreenComponent from './EnterScreenComponent';
import IntroScreenComponent from './IntroScreenComponent';
import AboutComponent from '../aboutComponents/About';
import App from '../../App'; // This is your main app component
import ContactPage from '../contactComponents/ContactPage';
import SolutionsPage from '../solutionsComponents/SolutionsPage';
import ServicesPage from '../servicesComponents/ServicesPage';
import ContactForm from '../contactComponents/ContactForm';
import NewsComponent from '../newsComponents/News';
import TagManager from 'react-gtm-module'
import PdfViewer from '../whitePapers/PdfViewer';
import ProtectedRoute from './ProtectedRoute';
import ProtectedPDFDownload from './ProtectedPDFDownload';

const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'pageview',
          page: location.pathname,
      });
  }, [location]);
  };

function Navigation() {

  useEffect(() => {
      TagManager.initialize({ gtmId: 'GTM-KND5QLFM' });
  }, []);

  return (
    <Router>
      <RouteTracker />
      <Routes>
        <Route path="/" element={<EnterScreenComponent />} />
        <Route path="/intro" element={<IntroScreenComponent />} />
        <Route path="/home" element={<App />} />
        <Route path="/about" element={<AboutComponent />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/solutions" element={<SolutionsPage />} />
        <Route path='/solutions/industry/:industry' element={<SolutionsPage />} />
        <Route path='/solutions/platforms/:platform' element={<SolutionsPage />} />
        <Route path='/solutions/useCase/:useCase' element={<SolutionsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path='/news' element={<NewsComponent />} />
        <Route path="/whitepaper/nextgeniam2025" element={<ProtectedPDFDownload />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

const RouteTracker = () => {
  usePageTracking();
  return null;
};

export default Navigation;
