import React, { useState, useEffect, useRef } from 'react';
import './SolutionsComponent2.css';
import FullScreenModal from '../fullscreenModalComponents/fullScreenModal';
import { useNavigate, useParams } from 'react-router-dom';

// Import industry images (SVG and GIF)
import EducationSvg from '../icons/education.svg';
import EducationGif from '../icons/education.gif';
import EnergyAndUtilitiesSVG from '../icons/energyandutilities.svg';
import EnergyAndUtilitiesGIF from '../icons/energyandutilities.gif';
import FinancialServicesSVG from '../icons/financialservices.svg';
import FinancialServicesGIF from '../icons/financialservices.gif';
import GamingSVG from '../icons/gaming.svg';
import GamingGIF from '../icons/gaming.gif';
import GovernmentSVG from '../icons/government.svg';
import GovernmentGIF from '../icons/government.gif';
import HealthcareSVG from '../icons/healthcare.svg';
import HealthcareGIF from '../icons/healthcare.gif';
import LegalServicesSVG from '../icons/legalservices.svg';
import LegalServicesGIF from '../icons/legalservices.gif';
import ManufacturingSVG from '../icons/manufacturing.svg';
import ManufacturingGIF from '../icons/manufacturing.gif';
import MediaEntertainmentSVG from '../icons/mediaentertainment.svg';
import MediaEntertainmentGIF from '../icons/mediaentertainment.gif';
import RetailSVG from '../icons/retail.svg';
import RetailGIF from '../icons/retail.gif';
import TechnologySVG from '../icons/technology.svg';
import TechnologyGIF from '../icons/technology.gif';

// Import detailed body content for each industry
import EducationContent from '../fullscreenModalComponents/EducationContent';
import EnergyAndUtilitiesContent from '../fullscreenModalComponents/EnergyAndUtilitiesContent';
import FinancialServicesContent from '../fullscreenModalComponents/FinancialServicesContent';
import GamingContent from '../fullscreenModalComponents/GamingContent';
import GovernmentContent from '../fullscreenModalComponents/GovernmentContent';
import HealthcareContent from '../fullscreenModalComponents/HealthcareContent';
import LegalServicesContent from '../fullscreenModalComponents/LegalServicesContent';
import ManufacturingContent from '../fullscreenModalComponents/ManufacturingContent';
import MediaEntertainmentContent from '../fullscreenModalComponents/MediaEntertainmentContent';
import RetailContent from '../fullscreenModalComponents/RetailContent';
import TechnologyContent from '../fullscreenModalComponents/TechnologyContent';

// Industry data array with detailed body content as JSX components
const industryData = [
  {
    title: 'Education',
    url: 'Education',
    description:
      'Empowering institutions with secure identity solutions to enhance learning experiences.',
    svgIcon: EducationSvg,
    gifIcon: EducationGif,
    body: <EducationContent />,
  },
  {
    title: 'Energy and Utilities',
    url: 'EnergyAndUtilities',
    description:
      'Ensuring safe and secure access to critical infrastructure for energy sectors.',
    svgIcon: EnergyAndUtilitiesSVG,
    gifIcon: EnergyAndUtilitiesGIF,
    body: <EnergyAndUtilitiesContent />,
  },
  {
    title: 'Financial Services',
    url: 'FinancialServices',
    description:
      'Protecting sensitive financial data and enabling secure digital experiences.',
    svgIcon: FinancialServicesSVG,
    gifIcon: FinancialServicesGIF,
    body: <FinancialServicesContent />,
  },
  {
    title: 'Gaming',
    url: 'Gaming',
    description:
      'Enhancing user experiences and protecting digital assets in the gaming industry.',
    svgIcon: GamingSVG,
    gifIcon: GamingGIF,
    body: <GamingContent />,
  },
  {
    title: 'Government and Public Sector',
    url: 'GovernmentAndPublicSector',
    description: 'Securing citizen data and streamlining government services.',
    svgIcon: GovernmentSVG,
    gifIcon: GovernmentGIF,
    body: <GovernmentContent />,
  },
  {
    title: 'Healthcare and Insurance',
    url: 'HealthcareAndInsurance',
    description:
      'Protecting patient information and ensuring compliance in healthcare.',
    svgIcon: HealthcareSVG,
    gifIcon: HealthcareGIF,
    body: <HealthcareContent />,
  },
  {
    title: 'Legal and Professional Services',
    url: 'LegalAndProfessionalServices',
    description:
      'Enabling secure access to sensitive client data in professional services.',
    svgIcon: LegalServicesSVG,
    gifIcon: LegalServicesGIF,
    body: <LegalServicesContent />,
  },
  {
    title: 'Manufacturing & Supply Chain',
    url: 'ManufacturingAndSupplyChain',
    description:
      'Optimizing operations and securing access to manufacturing processes.',
    svgIcon: ManufacturingSVG,
    gifIcon: ManufacturingGIF,
    body: <ManufacturingContent />,
  },
  {
    title: 'Media and Entertainment',
    url: 'MediaAndEntertainment',
    description:
      'Protecting content and enabling secure distribution in media industries.',
    svgIcon: MediaEntertainmentSVG,
    gifIcon: MediaEntertainmentGIF,
    body: <MediaEntertainmentContent />,
  },
  {
    title: 'Retail and E-Commerce',
    url: 'RetailAndE-Commerce',
    description:
      'Securing customer data and enabling seamless online shopping experiences.',
    svgIcon: RetailSVG,
    gifIcon: RetailGIF,
    body: <RetailContent />,
  },
  {
    title: 'Technology and Telecommunications',
    url: 'TechnologyAndTelecommunications',
    description:
      'Ensuring secure communications and protecting tech infrastructure.',
    svgIcon: TechnologySVG,
    gifIcon: TechnologyGIF,
    body: <TechnologyContent />,
  },
];

function SolutionsComponent2() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showInitialTitle, setShowInitialTitle] = useState(false);
  const [isTitleShrunk, setIsTitleShrunk] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    url: '',
    icon: '',
    body: null,
  });
  const componentRef = useRef(null);
  const animationRunOnce = useRef(false);

  const { industry } = useParams(); // Retrieves the "industry" parameter from the URL

  useEffect(() => {
    if (industry) {
      // Only perform the action if industry exists
      let currentIndustry = industryData.find(item => item.url === industry);
      console.log(currentIndustry);
      handleLearnMoreClick(currentIndustry);

    } else {
      console.log('Industry not found!');
    }
  }, [industry]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !animationRunOnce.current) {
            animationRunOnce.current = true; // Ensures the animation runs only once
            setShowInitialTitle(true);
            setTimeout(() => {
              setIsTitleShrunk(true);
              setTimeout(() => {
                setShowContent(true);
              }, 300); // Increased delay for content reveal
            }, 500);
          }
        });
      },
      { threshold: 0.4 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  // Make sure the component stays in its "shown" state after the initial animation
  useEffect(() => {
    const onScroll = () => {
      if (animationRunOnce.current) {
        setShowInitialTitle(true);
        setIsTitleShrunk(true);
        setShowContent(true);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleLearnMoreClick = (industry) => {
    setModalContent({
      title: industry.title,
      icon: industry.gifIcon,
      body: industry.body,
      url: industry.url
    });
    setIsModalOpen(true);
  };

  const navigate = useNavigate();

  const redirectToIndustry = (industry) => {
    const currentPath = window.location.pathname;
    const newPath = `${currentPath}/industry/${industry.url}`;

    console.log(newPath);
    navigate(newPath);
  };

  return (
    <div className="sc2_container" ref={componentRef}>
      <div
        className={`sc2_title ${showInitialTitle ? 'show' : ''} ${isTitleShrunk ? 'shrink' : ''
          }`}
      >
        <h2>
          By <span className="sc2_highlight">Industry</span>
        </h2>
      </div>
      <div
        className={`sc2_paragraph ${showContent ? 'sc2_paragraph--show' : ''}`}
      >
        <p>
          <span className="sc2_highlight">RAAH</span> thrives in tough
          verticals, learning more from every challenge.
          <br />
          We have a <span className="sc2_highlight">
            history of success
          </span>{' '}
          across industries:
        </p>
      </div>
      <div className={`sc2_content ${showContent ? 'sc2_content--show' : ''}`}>
        <div className="sc2_horizontalScrollContainer">
          {industryData.map((industry, index) => (
            <div
              key={index}
              className="sc2_tile"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              // onClick={() => handleLearnMoreClick(industry)}
              onClick={() => redirectToIndustry(industry)}
            >
              <img
                src={
                  hoveredIndex === index ? industry.gifIcon : industry.svgIcon
                }
                alt={industry.title}
                className="sc2_tileImage"
              />
              <h3 className="sc2_tileTitle">{industry.title}</h3>
              <p className="sc2_tileDescription">{industry.description}</p>
              <button
                className="sc2_learnMoreButton"
                onClick={(e) => {
                  e.stopPropagation();
                  // handleLearnMoreClick(industry);
                  redirectToIndustry(industry);
                }}
              >
                Learn More
              </button>
            </div>
          ))}
        </div>
      </div>
      <FullScreenModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          navigate("/solutions")
        }}
        title={modalContent.title}
        icon={modalContent.icon}
        body={modalContent.body}
        url={modalContent.url}
      />
    </div>
  );
}

export default SolutionsComponent2;
